/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //Toogle Slideout Tab
        $( ".js-slideout-trigger" ).click(function() {
          $( ".afs-slideout-trigger-wrap" ).toggleClass('afs-slideout-trigger-wrap--open');
        });
        //Close Slideout
        $( ".js-slideout-trigger--close" ).click(function() {
          $( ".afs-slideout-trigger-wrap" ).removeClass('afs-slideout-trigger-wrap--open');
        });

        $('.form-toggle__toggle').on('click', function(e){
          $('.form-toggle__form').addClass('form-toggle__form--visible');
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        //Stat Count
        $('.count').each(function() {
          var $counter = $(this),
              maxValue = $(this).data('value'),
              stepSize = $(this).data('step') || 1,
              duration = 1000,
              interval = duration / (maxValue / stepSize),
              counterValue = 0;
      
          $(this).text(counterValue);
          var stepper = setInterval(function() {
            counterValue += stepSize;
            var newText = counterValue;
            if (counterValue >= 1000000000) {
              var value = counterValue / 1000000000;
              value = counterValue === maxValue ? Math.round(value) : value.toFixed(1);
              newText = value + 'BIL';
            } else if (counterValue >= 1000000) {
              newText = Math.round(counterValue / 1000000) + 'MIL';
            }
            
            if (counterValue === maxValue) {
              newText += '<em>+</em>';
              clearInterval(stepper);
            }
            $counter.html(newText);
          }, interval);
        });

        //Capabilties Carousel
        $('.js-afs-pc-carousel').owlCarousel({
          items: 3
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
